<template>
  <div
    v-if="isActive"
    class="ui-cursor-pointer ui-mx-3"
    @click="authenticate()"
  >
    <img
      :src="iconSrc"
      :alt="`${provider} signin button`"
    >
  </div>
</template>

<script>
import GenericModal from '@/modals/generic';
import {
  socialLoginProviderWithoutEmailError,
  socialLoginProviderUnavailable,
  unconfirmedEmailError,
  requestCanceledError,
} from '@/utils/errors';
import { extractUtmFromCookies } from '@/utils/utm';
import { ACCOUNTS_OAUTH_CALLBACK_URL } from '@/consts';

const ACTION_TYPE_REGISTRATION = 'registration';
const ACTION_TYPE_BINDING = 'binding';

import { getQueryParam } from '@/utils/url';

export default {
  name: 'SocialLoginButton',
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isActive: true,
    oauth_state: '',
  }),
  computed: {
    iconSrc() {
      return this.$socialLogin.getIcon(this.provider);
    },
  },
  mounted() {
    this.oauth_state = getQueryParam('state');
    this.$socialLogin.isActive(this.provider).then(isActive => this.isActive = isActive);
  },
  methods: {
    async persistUtmIfExists() {
      try {
        const utm = extractUtmFromCookies();
        if (utm) {
          this.$services.bff.setUtm(utm);
        }
      } catch (_) {
        // possível erro não deve ser mostrado ao usuário
      }
    },

    async authenticate() {
      const providerName = this.provider;
      let providerToken = '';

      try {
        providerToken = await this.$socialLogin.authenticate(providerName);
      } catch (e) {
        if (e !== requestCanceledError) {
          throw e;
        }
      }
      if (!providerToken) {
        return;
      }

      try {
        const userAuth = await this.$services.accounts.socialLoginAuthenticate(providerName, providerToken, this.oauth_state);

        if (userAuth.oauth_callback_url) {
          localStorage.setItem(ACCOUNTS_OAUTH_CALLBACK_URL, userAuth.oauth_callback_url);
          if (localStorage.getItem(ACCOUNTS_OAUTH_CALLBACK_URL)) {
            return this.$router.push({ name: 'oauth-callback-redirect' });
          }
        }

        if (this.$route.query.parceiro) {
          localStorage.setItem('partner@estrategia', this.$route.query.parceiro);
        }

        switch (userAuth.action_type) {
        case ACTION_TYPE_REGISTRATION:
          this.$services.events.notifySocialLoginRegistration(providerName);
          break;
        case ACTION_TYPE_BINDING:
          this.$services.events.notifySocialLoginBinding(providerName);
          break;
        }

        this.persistUtmIfExists();

        // Envia evento de login em todos os casos
        this.$services.events.notifySocialLoginLogin(providerName);

        return this.$router.push({
          name: 'dados-complementares',
          query: this.$route.query,
        });
      } catch (e) {
        if (e === unconfirmedEmailError) {
          return this.$router.push({
            name: 'login-confirme-seu-email',
            query: {
              ...this.$route.query,
              email: e.meta.email,
            },
          });
        } else if (e === socialLoginProviderWithoutEmailError) {
          const params = { providerName, providerToken };
          const query = this.$route.query;
          return this.$router.push({ name: 'cadastro-social-login-sem-email', params, query });
        }

        const modalProps = {
          title: 'Não foi possível realizar o login pela rede social',
          subTitle: 'Por favor, aguarde alguns minutos e tente logar novamente',
          type: 'error',
          confirmButton: {
            label: 'Fechar',
            onClick: () => this.$modal.hide(),
          },
        };

        if (e === socialLoginProviderUnavailable) {
          modalProps.title = 'Rede social temporariamente indisponível';
          modalProps.subTitle = 'Por favor, aguarde alguns instantes ou escolha outra forma de realizar login';
        } else {
          console.error(e);
        }

        this.$modal.show({
          component: GenericModal,
          props: modalProps,
        });
      }
    },
  },
};
</script>
